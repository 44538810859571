<template>
  <div class="bg">
    <div class="header">
      <div class="headerL">
        <router-link to="/">
          <img src="@/assets/image/logo.png" alt="logo" />
        </router-link>
      </div>
      <div class="title">个人信息收集与使用清单</div>
    </div>
    <div class="main">
      <div class="h1">注册与登录</div>
      <ul>
        <li>收集的个人信息字段:手机号码、用户名、注册密码</li>
        <li>
          收集/使用目的:用于验证用户所注册的手机号码有效；创建用户名和密码，完成账号注册、登录
        </li>
        <li>收集方式:用户主动提供</li>
      </ul>
      <div class="h1">创建/导入账号</div>
      <ul>
        <li>收集的个人信息字段:手机号、邮箱、用户名、姓名</li>
        <li>收集/使用目的:用于创建账号及使用手机号/邮箱/用户名登录</li>
        <li>收集方式:用户主动提供</li>
      </ul>
      <div class="h1">上传头像</div>
      <ul>
        <li>收集的个人信息字段:图片信息</li>
        <li>收集/使用目的:用户的自我展示与表达</li>
        <li>收集方式:用户主动提供</li>
      </ul>
      <div class="h1">班级管理、报告管理、考务管理</div>
      <ul>
        <li>收集的个人信息字段:姓名、班级、年级、学校</li>
        <li>
          收集/使用目的:帮助班级的老师识别用户；方便按学校或班级安排考试，进行作答数据的统计分析，并生成报告
        </li>
        <li>收集方式:用户主动提供</li>
      </ul>
      <div class="h1">口语测评</div>
      <ul>
        <li>收集的个人信息字段:语音信息</li>
        <li>收集/使用目的:实现口语测评</li>
        <li>收集方式:用户主动提供</li>
      </ul>
      <div class="h1">日志信息</div>
      <ul>
        <li>收集的个人信息字段:User-Agent、IP地址、访问时间</li>
        <li>
          收集/使用目的:进行bug分析、数据统计，保障服务、改进和优化产品体验
        </li>
        <li>收集方式:系统自动收集</li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
p {
  text-indent: 2rem;
}
.header {
  background-color: #47475c;
  color: #ffffff;
  overflow: hidden;
}
.headerL {
  float: left;
  color: #ffffff;
  overflow: hidden;
}
.headerL img {
  height: 3.2rem;
  padding: 0.3rem;
  margin-left: 0.5rem;
}
.title {
  font-size: 2rem;
  text-align: center;
  line-height: 60px;
  width: 800px;
  margin: 0 auto;
}
.bg {
  background-color: #eee;
}
.main {
  padding: 1.786em 2.5em;
  background-color: #fff;
  max-width: 1000px;
  margin: 2rem auto;
  line-height: 2.2em;
  margin-bottom: 0;
}
.h1 {
  font-weight: 500;
  padding-top: 1.5rem;
}
ul {
  list-style-type: disc;
  padding-left: 1rem;
}
.pl1 {
  padding-left: 1rem;
}
.underL {
  text-decoration: underline;
}
</style>
